import React, { useEffect, useState } from 'react'
import { Table, Row, Col, Form, Input, Button, Card, Modal, Tag, Select, Space, message, Image as MyImage, Popconfirm, Tooltip } from 'antd';
import { PlusOutlined, EditOutlined, DeleteOutlined, CheckOutlined, LinkOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from 'react-redux';
import networkActions from '../../../redux/network/actions';
import countryAction from '../../../redux/country/actions'
import aggregatorAction from '../../../redux/aggregator/actions'
import noImageFound from '../../../assets/images/no_image.png';
import ReactCountryFlag from "react-country-flag";



const { getCountry } = countryAction;
const { getPreffedAggregatorList, getAggregator } = aggregatorAction;
const { getNetwork, getNetworksByCountryID, getNetworksByCountryIDForNetwork, showNetworkModel, deleteNetwork, resetNetworkMessage, closeNetworkModel, addNetwork, editNetwork, resetNetwork, showPrefAggregatorModel, closePrefAggregatorModel, updatePreffedAggregator } = networkActions;


const Network = () => {
  const { Option } = Select;
  const BASE_URL = process.env.REACT_APP_BASEURL;
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const countryResult = useSelector((state) => state.country.countryResult);
  const networkResult = useSelector((state) => state.network.networkResult);
  const { preferedAggregatorList, list } = useSelector((state) => state.aggregator);

  const { getNetworkListByCountryIdForNetwork, networkMessage, networkError, buttonLoading, networkFormModal, preferedAggregatorModal, tableLoading } = useSelector((state) => state.network);
  const [formButton, setFormButton] = useState('');
  const [formTitle, setFormTitle] = useState('');
  const [editId, setEditId] = useState();
  const [editFlag, setEditFlag] = useState(false);
  const [editImgUrl, setEditImageUrl] = useState("");
  const [imageUrl, setImageUrl] = useState('');
  const [preview, setPreview] = useState(false);
  const [showImg, setShowImg] = useState(false);
  const [networkId, setNetworkId] = useState(0);
  const [searchNetworkList, setSearchNetworkList] = useState([])
  const [searchData, setSearchData] = useState({
    countryID: "",
    networkID: "",
    status: ""
  });

  const [networkForm, setNetworkForm] = useState({
    NetworkName: "",
    NetworkLogoURL: "",
    CountryID: '',
    StartNumber: ''
  });

  const [preferedAggregatorForm, setpreferedAggregatorForm] = useState({
    PrefferedAggregatorId: ''
  });

  useEffect(() => {
    dispatch(getNetwork(searchData));
    dispatch(getCountry())
    dispatch(getAggregator())
    dispatch(getNetworksByCountryIDForNetwork(searchData.countryID ? searchData.countryID : 0))
  }, [])

  useEffect(() => {
    form.resetFields()
  }, [])


  const columns = [
    {
      title: '#',
      dataIndex: "key",
      key: "key",
      width: "5%",
    },
    {
      title: 'LOGO',
      dataIndex: 'logo',
      key: 'logo',
    },
    {
      title: 'NETWORK',
      dataIndex: 'network',
      key: 'network',
    },
    {
      title: 'PREFERRED AGGREGATOR',
      dataIndex: 'prefferedAggregator',
      key: 'prefferedAggregator',
    },
    {
      title: 'STATUS',
      dataIndex: 'status',
      key: 'status',
    },

    {
      title: 'ACTION',
      dataIndex: 'action',
      key: 'action',
    }
  ]

  var dataSource = networkResult && networkResult.length > 0 && networkResult.map((network, key) => {
    let prefAggregator = list && list.length > 0 && list.filter(item => item.ID == network.PrefferedAggregator)
    return {
      key: key + 1,
      logo: <MyImage
        width={100}
        height={40}
        src={`${BASE_URL}/images/network/${network.NetworkLogoURL}?${(Date.now())}`}
        fallback={noImageFound}
        alt='Network logo'
      />,
      network: network.NetworkName,
      prefferedAggregator: prefAggregator && prefAggregator.length > 0 ? prefAggregator[0].AggregatorName : '',
      status: <Tag color={network.IsActive === 1 ? "#1d89ca" : "#f50"} className="draft">{network.IsActive === 1 ? 'Active' : 'Inactive'}</Tag>,
      action: <Space style={{ width: '100%' }}>
        <Button type="default" icon={<EditOutlined />} size="large" onClick={() => handleEditForm(network.ID)} />
        {network.IsActive === 1 ? (
          <Tooltip title="Inactive">
            <Popconfirm
              title="Are you sure to inactive this network?"
              okText="Yes"
              placement="left"
              cancelText="No"
              onConfirm={() => confirmAction(network.ID, 0)}
            >
              <Button
                type="danger"
                ghost={true}
                icon={<DeleteOutlined />}
                size="large"
              />
            </Popconfirm>
          </Tooltip>
        ) : (
          <Tooltip title="Active">
            <Popconfirm
              title="Are you sure to active this network?"
              okText="Yes"
              placement="left"
              cancelText="No"
              onConfirm={() => confirmAction(network.ID, 1)}
            >
              <Button
                className="prop-btn-toggle"
                icon={<CheckOutlined />}
                size="large"
              />
            </Popconfirm>
          </Tooltip>
        )}

        <Tooltip title="Manage Aggregator">
          {<Button type="primary" ghost={true} icon={<LinkOutlined />} size="large" onClick={() => { handlePreferredAggregatorModal(network.ID, network.PrefferedAggregator); }} />}
        </Tooltip>
      </Space>
    }
  });

  const confirmAction = (id, status) =>
    new Promise((resolve) => {
      setTimeout(() => {
        handleDelete(id, status);
        resolve(null);
      }, 2000);
    });



  const handlePreferredAggregatorModal = (id, preffAggID) => {
    setNetworkId(id)
    setpreferedAggregatorForm(pre => ({
      ...pre,
      PrefferedAggregatorId: preffAggID ? preffAggID : ''
    }))
    dispatch(getPreffedAggregatorList(id))
    dispatch(showPrefAggregatorModel())
  }
  const handleSubmitPrefAggregator = (data) => {
    data.NetworkId = networkId;
    dispatch(updatePreffedAggregator(data, searchData))
  }

  const handleAddForm = () => {
    setFormTitle("Add Network");
    setFormButton("SUBMIT");
    setNetworkForm({
      NetworkName: "",
      NetworkLogoURL: "",
      CountryID: '',
      StartNumber: ''
    })
    setEditImageUrl('')
    dispatch(showNetworkModel())
    setEditFlag(false)

  }


  const handleEditForm = (userId) => {
    setShowImg(true)
    setFormTitle("Edit Network");
    setFormButton("UPDATE");
    setEditFlag(true)
    setEditId(userId);
    setPreview(false)
    var selectedNetwork = networkResult && networkResult.length > 0 && networkResult.filter(item => item.ID === userId);
    setEditImageUrl(selectedNetwork[0].NetworkLogoURL)
    setNetworkForm(pre => ({
      ...pre,
      NetworkName: selectedNetwork[0].NetworkName,
      NetworkLogoURL: selectedNetwork[0].NetworkLogoURL,
      CountryID: selectedNetwork[0].CountryID,
      StartNumber: selectedNetwork[0].StartNumber
    }))
    dispatch(showNetworkModel())
  }


  const handleCancel = () => {
    setShowImg(false)
    setNetworkForm(pre => ({
      ...pre,
      NetworkName: "",
      NetworkLogoURL: "",
      CountryID: '',
      StartNumber: ''
    }))
    setImageUrl('')
    setEditImageUrl('')
    form.resetFields()
    dispatch(closeNetworkModel())
    dispatch(resetNetwork())
    dispatch(closePrefAggregatorModel())
  }

  const fileToBase64 = (file, callback) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      if (typeof callback === 'function') {
        callback(reader.result);
      }
    };
    reader.readAsDataURL(file);
  }


  const handleImageChange = (e) => {
    const uploadImage = e.target.files[0]
    setNetworkForm(
      prevState => ({
        ...prevState,
        NetworkLogoURL: e.target.files[0]
      }))
    setImageUrl(URL.createObjectURL(e.target.files[0]));
    setPreview(true)
    if (uploadImage) {
      const image = new Image();
      image.src = URL.createObjectURL(uploadImage);
    }
  }

  const handleChange = (e) => {
    const fname = e.target.name;
    const fvalue = e.target.value;
    setNetworkForm(prevState => ({
      ...prevState,
      [fname]: fvalue
    }));
  }

  const handleSubmit = (data) => {
    fileToBase64(networkForm.NetworkLogoURL, (base64) => {
      data.NetworkLogoURL = base64;
      dispatch(addNetwork(data, searchData));
    });
  }

  const handleUpdate = (data) => {
    data.NetworkId = editId
    if (typeof data.NetworkLogoURL === 'object') {
      fileToBase64(networkForm.NetworkLogoURL, (base64) => {
        data.NetworkLogoURL = base64;
        dispatch(editNetwork(data, searchData));
      });
    }
    else {
      delete data.NetworkLogoURL;
      dispatch(editNetwork(data));
    }

  }

  const handleDelete = (ID, flag) => {
    dispatch(deleteNetwork(ID, flag, searchData))
  }
  const success = (text) => {
    message.success(text);
    dispatch(resetNetworkMessage()); // Reset messages after success message
  };

  const error = (text) => {
    message.error(text);
    dispatch(resetNetworkMessage()); // Reset messages after error message
  };

  const handleSearch = () => {
    dispatch(getNetwork(searchData));
  }
  const handleSelectCountry = (e) => {
    const countryId = e ? e : 0
    setSearchData(prevState => ({
      ...prevState,
      countryID: countryId
    }));
    dispatch(getNetworksByCountryIDForNetwork(countryId))
  };
  const handleSelectNetwork = (e) => {
    setSearchData(prevState => ({
      ...prevState,
      networkID: e
    }));
  };
  const handleSelectStatus = (e) => {
    setSearchData(prevState => ({
      ...prevState,
      status: e
    }));
  };
  return (
    <>
      {networkMessage !== null && networkMessage !== undefined && success(networkMessage)}
      {networkError !== null && networkError !== undefined && error(networkError)}

      <Card style={{ marginBottom: '8px' }}>
        <Form id="searchForm" layout="vertical" className="row-col" onFinish={handleSearch} >
          <Row gutter={[16, 16]}>
            <Col span={4}>
              <Form.Item className="username" label="Country" name="countryID">
                <Select placeholder="Select Country" onChange={handleSelectCountry} name="countryID" className="ant-select" showSearch optionFilterProp="children" defaultValue={searchData.countryID}>
                  <Option value="" key="0">ALL</Option>
                  {countryResult && countryResult.length > 0 && countryResult.map(country => (
                    <Option value={country.ID} key={country.ID}><ReactCountryFlag countryCode={country.ISO2} style={{ width: "20px", height: "20px" }} svg /> {country.CountryName}</Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            <Col span={4}>
              <Form.Item className="username" label="Network" name="networkID">
                <Select placeholder="Select Network" onChange={handleSelectNetwork} name="networkID" className="ant-select" showSearch optionFilterProp="children" defaultValue={searchData.networkID}>
                  <Option value="" key="0">ALL</Option>
                  {getNetworkListByCountryIdForNetwork && getNetworkListByCountryIdForNetwork.length > 0 && getNetworkListByCountryIdForNetwork.map(network => (
                    <Option value={network.ID} key={network.ID}>{network.NetworkName}</Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            <Col span={4}>
              <Form.Item className="username" label="Status" name="status">
                <Select placeholder="Select Status" onChange={handleSelectStatus} name="status" className="ant-select" showSearch optionFilterProp="children" defaultValue={searchData.networkID}>
                  <Option value="" key="0">ALL</Option>
                  <Option value={1} key={1}>Active</Option>
                  <Option value={0} key={0}>Inactive</Option>

                </Select>
              </Form.Item>
            </Col>

            <Col span={2} style={{ paddingTop: '4px' }}>
              <Button type="primary" htmlType='submit' loading={tableLoading} style={{ marginTop: '28px', float: 'right', fontWeight: 'bold', backgroundColor: '#223b8b', color: 'white', border: 'none' }}>SEARCH</Button>
            </Col>
          </Row>
        </Form>
      </Card>
      <div className="tabled">
        <Row gutter={[24, 0]}>
          <Col xs="24" xl={24}>
            <Card
              bordered={false}
              className="criclebox tablespace mb-24"
              title="Network"
              extra={
                <>
                  <Button type="ghost" style={{ float: 'right', fontWeight: 'bold', backgroundColor: '#223b8b', color: 'white', border: 'none' }} onClick={handleAddForm}><PlusOutlined />ADD NETWORK</Button>
                </>
              }
            >
              <div className="table-responsive">
                <Table
                  columns={columns}
                  dataSource={dataSource}
                  pagination
                  loading={tableLoading}
                  className="ant-border-space"
                />
              </div>
            </Card>

            <Modal title={formTitle} className='networkAddForm' style={{ top: 20}} width={700} closable={true} destroyOnClose={true} maskClosable={false} visible={networkFormModal}
              onOk={handleCancel}
              onCancel={handleCancel}
              footer={[
                <Button key="back" onClick={handleCancel}>
                  CANCEL
                </Button>,
                <Button type="ghost" key="submit" form="dealerForm" htmlType="submit" style={{ backgroundColor: '#223b8b', color: 'white', border: 'none' }}
                  loading={buttonLoading}
                > {formButton} </Button>
              ]}
            >
              <Form id="dealerForm" layout="vertical" onFinish={editFlag ? handleUpdate : handleSubmit} initialValues={networkForm} >
                <Row gutter={[16, 0]}>
                  <Col span={12} >
                    <Form.Item
                      className="NetworkName"
                      name="NetworkName"
                      label="Network Name"
                      rules={[{ required: true, message: 'Please input Network name !' }]}
                    >
                      <Input placeholder="Network Name" name="NetworkName" onChange={handleChange} />
                    </Form.Item>
                  </Col>

                  <Col span={12} >
                    <Form.Item label='Country' className="CountryID" name="CountryID" rules={[{ required: true, message: "Please Select Country!", }]}>
                      <Select>
                        <Select.Option value={''} key={''}>Select</Select.Option>
                        {countryResult && countryResult.length > 0 && countryResult.map(country => (
                          <Select.Option value={country.ID} key={country.ID}>{country.CountryName}</Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={[16, 16]}>
                  <Col span={12}>
                    <Form.Item
                      className="NetworkLogoURL"
                      label="Network Logo"
                      name="NetworkLogoURL"
                    >
                      <Input type="file" accept=".jpg, .jpeg, .png" name="NetworkLogoURL" className="NetworkLogoURL" onChange={handleImageChange} />
                      {/* <p style={{ color: isValidImage ? "gray" : "red", fontWeight: "bold" }}>
                  <span style={{ color: "#eb0808", fontWeight: "bold" }}>Note - </span>
                  Please upload only landscape images for a better view in your website. The image size should be less than 1 Mb, and its dimensions should not exceed 3000 pixels and  width of image should be greater than height. The acceptable file extensions are .jpg, .jpeg, or .png.
                </p> */}
                      {preview &&
                        <MyImage
                          width={200}
                          src={imageUrl}
                        //fallback={'noImageFound'}
                        />
                      }
                      {!preview && showImg &&

                        <MyImage
                          width={200}
                          src={`${BASE_URL}/images/network/${editImgUrl}?${(Date.now())}`}
                        //src={`${process.env.REACT_APP_BASEURL}/network/${editImgUrl}`}
                        // fallback={'noImageFound'}
                        />
                      }
                    </Form.Item>
                  </Col>
                  <Col span={12} >
                    <Form.Item
                      className="StartNumber"
                      name="StartNumber"
                      label="Start Number"
                    // rules={[{ required: true, message: 'Please input start number !' }]}
                    >
                      <Input placeholder="Start Number" name="StartNumber" onChange={handleChange} />
                    </Form.Item>
                  </Col>
                </Row>

              </Form>




            </Modal>



            <Modal title={'MANAGE AGGREGATOR'} style={{ top: 20 }} width={500} closable={true} destroyOnClose={true} maskClosable={false} visible={preferedAggregatorModal}
              onOk={handleCancel}
              onCancel={handleCancel}
              footer={[
                <Button key="back" onClick={handleCancel}>
                  CANCEL
                </Button>,
                <Button type="ghost" key="submit" form="PrefferedAggregatorForm" htmlType="submit" style={{ backgroundColor: '#223b8b', color: 'white', border: 'none' }}
                  loading={buttonLoading}
                > SUBMIT </Button>
              ]}
            >
              <Form id="PrefferedAggregatorForm" layout="vertical" onFinish={handleSubmitPrefAggregator} initialValues={preferedAggregatorForm} >
                <Row gutter={[16, 0]}>
                  <Col span={24} >
                    <Form.Item label='Preferred Aggregator' className="PrefferedAggregatorId" name="PrefferedAggregatorId" rules={[{ required: true, message: "Please Select Preferred Aggregator!", }]}>
                      <Select>
                        <Select.Option value={''} key={''}>Select</Select.Option>
                        {preferedAggregatorList && preferedAggregatorList.length > 0 && preferedAggregatorList.map(aggregator => (
                          <Select.Option value={aggregator.ID} key={aggregator.ID}>{aggregator.AggregatorName}</Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>

              </Form>

            </Modal>
          </Col>
        </Row>
      </div>

    </>
  )
}

export default Network
